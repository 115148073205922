<template>
  <div class="ommunity">
    <Header />
    <div class="content">
      <div class="title">智慧社区解决方案</div>
      <p class="title-content">
        智慧社区是社区管理的一种新理念,是新形势下社会管理创新的一种新模式。
      </p>
      <p class="title-content">
        智慧社区是指充分利用物联网、云计算、移动互联网等新一代信息技术的集成应用，通过全面数字化、智能化建设，为社区居民提供一个安全、舒适、便利的现代化、智慧化生活环境，从而形成基于信息化、智能化社会管理与服务的一种新的管理形态的社区。
      </p>
      <div style="text-align: center">
        <img
          class="content_img"
          src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/web/firm_1.png"
          alt="智慧园区解决方案"
        />
      </div>
      <h4 style="font-weight:400;">服务应用</h4>
      <div class="service_wrap">
        <div v-for="item in service" :key="item.title" class="service_item">
          <img :src="item.imgUrl" :alt="item.title" />
          <p class="p-title">{{ item.title }}</p>
        </div>
      </div>
      <div class="footer" @click="jumpTo">下一篇：智慧消防解决方案 >></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  name: 'Community',
  components: { Header },
  data() {
    return {
      service: [
        {
          title: "居住安全",
          imgUrl: require("../../static/images/icon_jzhj.png")
        },
        {
          title: "宜居环境",
          imgUrl: require("../../static/images/icon_yjhj.png")
        },
        {
          title: "党建引领",
          imgUrl: require("../../static/images/icon_djyl .png")
        },
        {
          title: "政务公开",
          imgUrl: require("../../static/images/icon_zwgk.png")
        },
        {
          title: "群防群治",
          imgUrl: require("../../static/images/icon_qfqz.png")
        },
        {
          title: "文化教育",
          imgUrl: require("../../static/images/icon_whjy.png")
        },
        {
          title: "医疗服务",
          imgUrl: require("../../static/images/icon_ylfw .png")
        },
        {
          title: "健康养老",
          imgUrl: require("../../static/images/icon_jkyl.png")
        },
        {
          title: "办事指南",
          imgUrl: require("../../static/images/icon_bszn.png")
        },
        {
          title: "社区议事",
          imgUrl: require("../../static/images/icon_sqys.png")
        }
      ]
    }
  },
  methods: {
    jumpTo() {
      this.$router.push('/details/fire')
    }  
  }
}
</script>

<style scoped>
.title {
  font-size: 0.4rem;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin-bottom: 0.75rem;
}
.title-content {
  font-weight: 400;
  color: #666666;
  line-height: 0.64rem;
  font-size: 0.37rem;
  margin-bottom: 0.91rem;
  text-indent: 2em;
}
.content_img {
  width: 9.15rem;
  height: 4.88rem;
}
h4 {
  font-size: 0.4rem;
  color: rgba(39, 32, 27, 1);
  margin-top: 1.08rem;
  margin-bottom: 0.89rem;
}
.service_wrap {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.p-title {
  font-size: 0.32rem;
  font-weight: 400;
  color: #999999;
}
.service_item {
  width: 1.83rem;
  margin-bottom: 1.31rem;
}
.service_item img {
  width: 1.31rem;
}
.footer {
  cursor: pointer;
  margin-right: 0.2rem;
  text-align: right;
  width: 100%;
  height: 0.31rem;
  font-size: 0.32rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem;
}
</style>
