<template>
  <div class="header">
    <!-- <span @click="toHome"><svg-icon icon-class="logo" class="logo" /></span>
    <div>
      <img src="../static/images/home-s-b.png" alt="">
    </div> -->
    <div>
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../static/images/home-h-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;vertical-align: super;margin-left: 0.1rem;" src="../static/images/home-h-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../static/images/home-s-h.png" alt="">
        </div>
      </div>
      <div style="background: #CACACA;height: 0.03rem;"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
    methods: {
      jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      }
    }
  }
</script>

<style lang="scss" scoped>

.header{
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-size: 100% 100%;
}
.header-img{
  padding: 0.3rem 0.4rem 0 0.4rem;
  display: flex;
  justify-content: space-between;
}
.header-text{
  width: 6.6rem;
  position: absolute;
  margin-left: -3.2rem;
  top: 38%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 6px;
}
</style>